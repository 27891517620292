<template>
  <body>
    <div class="app flex-row align-items-center">
      <div class="container">
        <b-row class="justify-content-center">
          <b-col md="6">
            <div class="clearfix">
              <h1 class="float-left display-3 mr-4">404</h1>
              <h4 class="pt-3">Oeps! U bent verdwaald.</h4>
              <p class="text-muted">De pagina die u zoekt is niet beschikbaar.</p>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    
  </body>
</template>


<script>
export default {
  name: 'Page404'
}
</script>
<style scoped>
  
body {
  background-color: #FFFFFF;
}

.mainbox {
  background-color: #1355e4;
  margin: auto;
  height: 600px;
  width: 600px;
  position: relative;
}

  .err {
    color: #f06f06;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 20%;
    top: 8%;
  }

.far {
  position: absolute;
  font-size: 8.5rem;
  left: 42%;
  top: 15%;
  color: #f06f06;
}

 .err2 {
    color: #f06f06;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 68%;
    top: 8%;
  }

.msg {
      color: #f06f06;

    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position:absolute;
    left: 16%;
    top: 45%;
    width: 75%;
  }

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: underline;
}
</style>